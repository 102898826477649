.page {
	h1 {
		font-weight:700;
		color:$red;
	}
}

.post-page {
	.page-content {
		padding-top:40px;
	}
}

.share-links {
	text-align:right;
	ul {
		margin:0;
		padding:0;
	}
	li {
		list-style:none;
		display:inline-block;
		margin-left:10px;
	}
}