.news-listing {
	h1.strip-title {
		color:$red;
	}
	.post-card {
		margin-bottom:30px;
	}
	.post-card {
		.inner {
			padding-left:0;
			@media only screen and (max-width: 768px) { 
				padding-left: 30px;
			}
		}
	}
}

.post-info {
	color:$grey_dark;
	a {
		color:$charcoal;
		&:hover {
			color:$red;
		}
	}
}

.thumb-container {
	width:100%;
	padding-bottom:92%;
	overflow:hidden;
	display:block;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;
	transition:background-position 0.3s;
}

.searchforms {
	text-align: right;
	label {
		margin-right: 10px;
    	margin-left: 20px;
	}
}

.blog-search {
	text-align:right;
	display: inline-block;
	padding-bottom:16px;
	.search-container {
		background:$white;
		display:inline-block;
		padding:5px 10px;
		line-height:24px;
		font-size: 16px;
		border-bottom:3px $turquoise_dark solid;
		.search-input {
			border:0;
			padding:0;
		}
	}
	button {
		font-family: "FontAwesome";
		font-style:normal;
		border:0;
		background:none;
		color:$turquoise;
		i {
			font-style:normal;
		}
	}
}

.pagination {
	width:100%;
	text-align:center;
}

select{
	border-width:0 0 3px 0;
	border-color:$turquoise_dark;
	padding:8px 10px;
	background-color: white;
	display: inline-block;
	font: inherit;
	line-height: 18px; 
	padding: 0.5em 3.5em 0.5em 1em;
	height: 39px;
	margin: 0;      
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
}

select {
  background-image:
    linear-gradient(45deg, transparent 50%, $turquoise_dark 50%),
    linear-gradient(135deg, $turquoise_dark 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

select:focus, {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, $turquoise_dark, $turquoise_dark);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  outline: 0;
}



.page-numbers {
	@extend .btn-default;
}

.page-numbers.current {
	background-color: $turquoise_dark;
}

a.page-numbers:after, a.page-numbers:active:after, .page-numbers:after {
	display: none;
}

a.prev, a.prev:active, a.prev:visited, .prev {
	position: absolute;
    left: 14px;
    @media only screen and (max-width: 1024px) { 
		width: 100%;
		position: relative;
		left:0;
		margin-bottom: 6px;
	}
}

a.next, a.next:active, a.next:visited, .next {
	position: absolute;
    right: 14px;
    @media only screen and (max-width: 1024px) { 
		width: 100%;
		position: relative;
		right:0;
		margin-top: 6px;
	}
}

a.page-numbers.prev:after, .page-numbers.prev:after {
	display: inline-block;
    float: left;
    left: 0;
    margin-right: 11px;
    transform: rotate(180deg);
    margin-left: 0;
}

a.page-numbers.next:after, .page-numbers.next:after {
	display: inline-block;
}


.current {
	&:before {
		display: none;
	}
}

.page-numbers {
	&:after {
		display: none;
	}
}

.page-numbers {
	&:after {
		display: none;
	}
}

.inactive {
	border:0;
	background-color: $grey_dark;
	pointer-events: none;
	&:before {
		height:0;
	}
	&:focus {
		outline:0;
	}
	&:hover {
		&:before {
			height:0;
		}
	}
}