/* COLOURS */

$red:				#ac1f2d;
$charcoal:			#2f2f2f;
$grey:				#f2f2f2;
$grey_med:			#dfdfdf;
$grey_dark:			#919191;
$white:				#fff;
$turquoise:			#44978c;
$turquoise_dark: 	#288376;

/* BOOTSTRAP */

@import "../../bower_components/bootstrap/scss/_variables.scss";
@import "../../bower_components/bootstrap/scss/_mixins.scss";
@import "../../bower_components/bootstrap/scss/_grid.scss";

/* BASE */

body {
	margin:0;
	padding:0;
	font-size:16px;
	line-height: 24px;
	font-family: 'Lato', sans-serif;
}

* {
	box-sizing:border-box;
}

img {
	max-width: 100%;
}

h1, h2 {
	text-transform: uppercase;
	font-weight: bold;
	margin-top:0;
}

h1 {
	font-size: 27px;
	line-height: 34px;
}

h2 {
	font-size: 22px;
	line-height:30px;
	color:$red;
}

h3 {
	font-size: 22px;
	line-height: 30px;
	color:$charcoal;
	margin-top:0;
	font-weight:400;
}

a, a:active, a:visited {
	color: $turquoise;
}

@import "partials/_wysiwyg-output.scss";

/* GENERAL */

.clear {
	clear:both;
	float:none;
}

.btn-default, a.btn-default, a.btn-default:active, a.btn-default:visited {
	display: inline-block;
	background: $turquoise;
	position: relative;
	color: $white;
	padding:10px 15px;
	text-transform: uppercase;
	color:$white;
	font-weight:bold;
	text-decoration: none;	
	box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.07);
	span {
		position: relative;
		z-index: 1;
	}
	&:before {
		height:3px;
		width:100%;
		position: absolute;
		bottom:0;
		left:0;
		background: $turquoise_dark;
		content:"";
		transition:height 0.3s;
		z-index:0;
	}
	&:after {
		font-weight: 400;
		content:"\f0da";
		display: inline-block;
		margin-left:10px;
		font-size:18px;
		font-family: "FontAwesome";
		position: relative;
		z-index: 1;
		left:0;
		transition:all 0.3s;
	}
	&.download:after {
		content:"\f019";
		top:0;
	}
	&:hover {
		color:$white;
		text-decoration: none;
		&:before {
			height:100%;
		}
		&:after {
			left:3px;
		}
		&.download:after {
			left:0;
			top:3px;
		}
	}
}

/* PARTIALS */
@import "partials/_structural.scss";
@import "partials/_header.scss";
@import "partials/_footer.scss";
@import "partials/_home.scss";
@import "partials/_page.scss";
@import "partials/_resources.scss";
@import "partials/_blog.scss";


