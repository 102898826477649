.banner {
	min-height:400px;
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index:1;
	&.home-banner {
		min-height:500px;
		@media only screen and (max-width: 1024px) { 
			min-height: 380px;
		}
		@media (max-width: 767px) {
			min-height: 200px;
			max-height: 200px;
		}
	}

	@media (max-width: 767px) {
		min-height: 200px;
		max-height: 200px;
	}
}

.sep {
	display: block;
	width:100%;
	margin:15px 0;
	height:2px;
	&:before {
		content:"";
		display: block;
		width:100px;
		background: $red;
		height:2px;
	}
}

.content-strip {
	padding:60px 0;
	&.grey {
		background: $grey;
		.content-panel {
			background: $white;
		}
	}
	h2 {
		font-size: 28px;
		@media only screen and (max-width: 1024px) { 
			font-size: 22px;
		}
	}
	.strip-title {
		margin-bottom:15px;
	}
}

.content-panel {
	padding:30px;
	background: $grey;
	box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.05);
	position:relative;
	h2 {
		line-height:44px;
	}
	&.post-card {
		padding:0;
		.inner {
			padding:30px;
		}

		img {
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.follow {
		color:lighten($red, 50%);
		text-transform: uppercase;
		font-weight:bold;
		position:absolute;
		right:30px;
		top:30px;
		.btn-default {
			margin-left:10px;
		}
		@media only screen and (max-width: 1024px) { 
			position: relative;
			left:0;
			top:0;
			margin-bottom: 30px;
		}
	}
}

.red-bg {
	background: $red;
	color:$white;
	h2 {
		color:$white;
	}
	a.btn-default, a.btn-default:active, a.btn-default:visited {
		background:$white;
		color:$red;
		&:before {
			background:$grey_med;
		}
	}
}

.page-content {

	span.wpcf7-not-valid-tip {
	    color: $white;
	    background-color: $red;
	    font-size: 1em;
	    font-weight: normal;
	    display: block;
	    padding: 10px 20px;
	    display: block;
	    clear: both;
	    margin-top: 0px;
	    width: 80%;

	}

	span.wpcf7-valid-tip {
	    color: $white;
	    background-color: $turquoise;
	    font-size: 1em;
	    font-weight: normal;
	    display: block;
	    padding: 10px 20px;
	    display: block;
	    clear: both;
	    margin-top: 0px;
	    width: 80%;
	   
	}

	div.wpcf7-validation-errors {
		color: $white;
	    background-color: $grey_dark;
	    border:0;
	    font-size: 1em;
	    font-weight: normal;
	    display: block;
	    padding: 10px 20px;
	    display: block;
	    clear: both;
	    margin-top: 0px;
	    width: 80%;
	    margin-left:0;
	}

	div.wpcf7-mail-sent-ok {
		color: $white;
	    background-color: $turquoise;
	    border:0;
	    font-size: 1em;
	    font-weight: normal;
	    display: block;
	    padding: 10px 20px;
	    display: block;
	    clear: both;
	    margin-top: 0px;
	    width: 80%;
	    margin-left:0;
	}

	input, button, select, textarea {
	    margin: 0;
	    line-height: inherit;
	    width: 80%;
	    padding: 10px;
	    border: 1px solid $grey_med;
	}

	input[type=submit] {
		border: 1px solid $turquoise;
	}

	input[type=submit]:before {
	    height: 3px;
	    width: 100%;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    background: #288376;
	    content: "";
	    transition: height 0.3s;
	    z-index: 0;
	}

	input:focus, select:focus, textarea:focus {
		border: 1px solid $turquoise;
		outline: none;
	}

	select {
	    height: 46px;
	    padding:0.5em 3.5em 0.5em 1em;
	}

	.contactSubmit {
	    width: 80%;
	    height: auto;
	    overflow: hidden;
	    text-align: center;
	    cursor: pointer;
	}

	.contactSubmitHidden {
		display: none;
	}

}

.homepage-mobile-whitespace {
	width: 100%;
	height: 25px;
	display: block;
	background-color: #fff;

	@media (min-width: 768px) {
		display: none;
	}
}

.homepage-mobile-greyspace {
	width: 100%;
	height: 25px;
	display: block;
	background-color: $grey;

	@media (min-width: 768px) {
		display: none;
	}
}