/* COLOURS */
/* BOOTSTRAP */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 34em) {
  .container {
    max-width: 34rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 72.25rem;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.3333333333%;
}

.col-xs-pull-2 {
  right: 16.6666666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333333333%;
}

.col-xs-pull-5 {
  right: 41.6666666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333333333%;
}

.col-xs-pull-8 {
  right: 66.6666666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333333333%;
}

.col-xs-pull-11 {
  right: 91.6666666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 34em) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 48em) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 62em) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 75em) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }
  .col-xl-1 {
    width: 8.3333333333%;
  }
  .col-xl-2 {
    width: 16.6666666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.3333333333%;
  }
  .col-xl-5 {
    width: 41.6666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.3333333333%;
  }
  .col-xl-8 {
    width: 66.6666666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.3333333333%;
  }
  .col-xl-11 {
    width: 91.6666666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-pull-1 {
    right: 8.3333333333%;
  }
  .col-xl-pull-2 {
    right: 16.6666666667%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.3333333333%;
  }
  .col-xl-pull-5 {
    right: 41.6666666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.3333333333%;
  }
  .col-xl-pull-8 {
    right: 66.6666666667%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.3333333333%;
  }
  .col-xl-pull-11 {
    right: 91.6666666667%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-push-1 {
    left: 8.3333333333%;
  }
  .col-xl-push-2 {
    left: 16.6666666667%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.3333333333%;
  }
  .col-xl-push-5 {
    left: 41.6666666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.3333333333%;
  }
  .col-xl-push-8 {
    left: 66.6666666667%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.3333333333%;
  }
  .col-xl-push-11 {
    left: 91.6666666667%;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-offset-0 {
    margin-left: 0%;
  }
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

/* BASE */
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

h1, h2 {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0;
}

h1 {
  font-size: 27px;
  line-height: 34px;
}

h2 {
  font-size: 22px;
  line-height: 30px;
  color: #ac1f2d;
}

h3 {
  font-size: 22px;
  line-height: 30px;
  color: #2f2f2f;
  margin-top: 0;
  font-weight: 400;
}

a, a:active, a:visited {
  color: #44978c;
}

.wysiwyg {
  line-height: 1.6;
}

.wysiwyg a {
  text-decoration: none;
}

.wysiwyg a:hover {
  border-bottom: 1px solid;
}

.wysiwyg abbr {
  border-bottom: 1px dotted;
  cursor: help;
}

.wysiwyg cite {
  font-style: italic;
}

.wysiwyg hr {
  background: #ac1f2d;
  border: none;
  display: block;
  height: 1px;
  margin-bottom: 1.4em;
  margin-top: 1.4em;
}

.wysiwyg img {
  vertical-align: text-bottom;
}

.wysiwyg ins {
  background-color: lime;
  text-decoration: none;
}

.wysiwyg mark {
  background-color: yellow;
}

.wysiwyg small {
  font-size: 0.8em;
}

.wysiwyg strong {
  font-weight: 700;
}

.wysiwyg sub,
.wysiwyg sup {
  font-size: 0.8em;
}

.wysiwyg sub {
  vertical-align: sub;
}

.wysiwyg sup {
  vertical-align: super;
}

.wysiwyg p, .wysiwyg dl, .wysiwyg ol, .wysiwyg ul, .wysiwyg blockquote, .wysiwyg pre, .wysiwyg table {
  margin-bottom: 1.4em;
}

.wysiwyg p:last-child, .wysiwyg dl:last-child, .wysiwyg ol:last-child, .wysiwyg ul:last-child, .wysiwyg blockquote:last-child, .wysiwyg pre:last-child, .wysiwyg table:last-child {
  margin-bottom: 0;
}

.wysiwyg p:empty {
  display: none;
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
  font-weight: 700;
  line-height: 1.2;
}

.wysiwyg h1:first-child, .wysiwyg h2:first-child, .wysiwyg h3:first-child, .wysiwyg h4:first-child, .wysiwyg h5:first-child, .wysiwyg h6:first-child {
  margin-top: 0;
}

.wysiwyg h1 {
  font-size: 2.4em;
  margin-bottom: 0.58333em;
  margin-top: 0.58333em;
  line-height: 1;
}

.wysiwyg h2 {
  font-size: 1.6em;
  margin-bottom: 0.875em;
  margin-top: 1.75em;
  line-height: 1.1;
}

.wysiwyg h3 {
  font-size: 1.3em;
  margin-bottom: 1.07692em;
  margin-top: 1.07692em;
}

.wysiwyg h4 {
  font-size: 1.2em;
  margin-bottom: 1.16667em;
  margin-top: 1.16667em;
}

.wysiwyg h5 {
  font-size: 1.1em;
  margin-bottom: 1.27273em;
  margin-top: 1.27273em;
}

.wysiwyg h6 {
  font-size: 1em;
  margin-bottom: 1.4em;
  margin-top: 1.4em;
}

.wysiwyg dd {
  margin-left: 1.4em;
}

.wysiwyg ol,
.wysiwyg ul {
  list-style-position: outside;
  margin-left: 1.4em;
}

.wysiwyg ol {
  list-style-type: decimal;
}

.wysiwyg ol ol {
  list-style-type: lower-alpha;
}

.wysiwyg ol ol ol {
  list-style-type: lower-roman;
}

.wysiwyg ol ol ol ol {
  list-style-type: lower-greek;
}

.wysiwyg ol ol ol ol ol {
  list-style-type: decimal;
}

.wysiwyg ol ol ol ol ol ol {
  list-style-type: lower-alpha;
}

.wysiwyg ul {
  list-style-type: disc;
}

.wysiwyg ul ul {
  list-style-type: circle;
}

.wysiwyg ul ul ul {
  list-style-type: square;
}

.wysiwyg ul ul ul ul {
  list-style-type: circle;
}

.wysiwyg ul ul ul ul ul {
  list-style-type: disc;
}

.wysiwyg ul ul ul ul ul ul {
  list-style-type: circle;
}

.wysiwyg blockquote {
  border-left: 4px solid #ac1f2d;
  padding: 0.6em 1.2em;
  min-height: 110px;
  position: relative;
  padding-left: 90px;
  font-size: 1.5em;
  padding-right: 90px;
}

.wysiwyg blockquote:before {
  content: "\f10d";
  font-family: sans-serif;
  position: absolute;
  left: 24px;
  top: 22px;
  display: inline-block;
  font: normal normal normal 44px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.wysiwyg blockquote:after {
  content: "\f10e";
  font-family: sans-serif;
  position: absolute;
  right: 46px;
  top: 38px;
  display: inline-block;
  font: normal normal normal 44px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.wysiwyg blockquote p {
  margin-bottom: 0;
}

.wysiwyg code,
.wysiwyg kbd,
.wysiwyg samp,
.wysiwyg pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 0.9em;
}

.wysiwyg code,
.wysiwyg kbd,
.wysiwyg samp {
  border-radius: 3px;
  line-height: 1.77778;
  padding: 0.1em 0.4em 0.2em;
  vertical-align: baseline;
}

.wysiwyg .alignright {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .wysiwyg .alignright {
    width: 100%;
    height: 100%;
  }
}

.wysiwyg .alignleft {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .wysiwyg .alignleft {
    width: 100%;
    height: 100%;
  }
}

.wysiwyg .aligncenter {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.wysiwyg pre {
  overflow: auto;
  padding: 1em 1.2em;
}

.wysiwyg pre code {
  background: none;
  font-size: 1em;
  line-height: 1em;
}

.wysiwyg figure {
  margin-bottom: 2.8em;
  text-align: center;
}

.wysiwyg figure:first-child {
  margin-top: 0;
}

.wysiwyg figure:last-child {
  margin-bottom: 0;
}

.wysiwyg figcaption {
  font-size: 0.8em;
  margin-top: 0.875em;
}

.wysiwyg table {
  width: 100%;
}

.wysiwyg table pre {
  white-space: pre-wrap;
}

.wysiwyg th,
.wysiwyg td {
  font-size: 1em;
  padding: 0.7em;
  border: 1px solid #ac1f2d;
  line-height: 1.4;
}

.wysiwyg thead tr,
.wysiwyg tfoot tr {
  background-color: whitesmoke;
}

.wysiwyg thead th,
.wysiwyg thead td,
.wysiwyg tfoot th,
.wysiwyg tfoot td {
  font-size: 0.9em;
  padding: 0.77778em;
}

.wysiwyg thead th code,
.wysiwyg thead td code,
.wysiwyg tfoot th code,
.wysiwyg tfoot td code {
  background-color: white;
}

.wysiwyg tbody tr {
  background-color: white;
}

/* GENERAL */
.clear {
  clear: both;
  float: none;
}

.btn-default, .page-numbers, a.btn-default, a.page-numbers, a.btn-default:active, a.page-numbers:active, a.btn-default:visited, a.page-numbers:visited {
  display: inline-block;
  background: #44978c;
  position: relative;
  color: #fff;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.07);
}

.btn-default span, .page-numbers span, a.btn-default span, a.page-numbers span, a.btn-default:active span, a.page-numbers:active span, a.btn-default:visited span, a.page-numbers:visited span {
  position: relative;
  z-index: 1;
}

.btn-default:before, .page-numbers:before, a.btn-default:before, a.page-numbers:before, a.btn-default:active:before, a.page-numbers:active:before, a.btn-default:visited:before, a.page-numbers:visited:before {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #288376;
  content: "";
  transition: height 0.3s;
  z-index: 0;
}

.btn-default:after, .page-numbers:after, a.btn-default:after, a.page-numbers:after, a.btn-default:active:after, a.page-numbers:active:after, a.btn-default:visited:after, a.page-numbers:visited:after {
  font-weight: 400;
  content: "\f0da";
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  font-family: "FontAwesome";
  position: relative;
  z-index: 1;
  left: 0;
  transition: all 0.3s;
}

.btn-default.download:after, .download.page-numbers:after, a.btn-default.download:after, a.download.page-numbers:after, a.btn-default:active.download:after, a.page-numbers:active.download:after, a.btn-default:visited.download:after, a.page-numbers:visited.download:after {
  content: "\f019";
  top: 0;
}

.btn-default:hover, .page-numbers:hover, a.btn-default:hover, a.page-numbers:hover, a.btn-default:active:hover, a.page-numbers:active:hover, a.btn-default:visited:hover, a.page-numbers:visited:hover {
  color: #fff;
  text-decoration: none;
}

.btn-default:hover:before, .page-numbers:hover:before, a.btn-default:hover:before, a.page-numbers:hover:before, a.btn-default:active:hover:before, a.page-numbers:active:hover:before, a.btn-default:visited:hover:before, a.page-numbers:visited:hover:before {
  height: 100%;
}

.btn-default:hover:after, .page-numbers:hover:after, a.btn-default:hover:after, a.page-numbers:hover:after, a.btn-default:active:hover:after, a.page-numbers:active:hover:after, a.btn-default:visited:hover:after, a.page-numbers:visited:hover:after {
  left: 3px;
}

.btn-default:hover.download:after, .page-numbers:hover.download:after, a.btn-default:hover.download:after, a.page-numbers:hover.download:after, a.btn-default:active:hover.download:after, a.page-numbers:active:hover.download:after, a.btn-default:visited:hover.download:after, a.page-numbers:visited:hover.download:after {
  left: 0;
  top: 3px;
}

/* PARTIALS */
.banner {
  min-height: 400px;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.banner.home-banner {
  min-height: 500px;
}

@media only screen and (max-width: 1024px) {
  .banner.home-banner {
    min-height: 380px;
  }
}

@media (max-width: 767px) {
  .banner.home-banner {
    min-height: 200px;
    max-height: 200px;
  }
}

@media (max-width: 767px) {
  .banner {
    min-height: 200px;
    max-height: 200px;
  }
}

.sep {
  display: block;
  width: 100%;
  margin: 15px 0;
  height: 2px;
}

.sep:before {
  content: "";
  display: block;
  width: 100px;
  background: #ac1f2d;
  height: 2px;
}

.content-strip {
  padding: 60px 0;
}

.content-strip.grey {
  background: #f2f2f2;
}

.content-strip.grey .content-panel {
  background: #fff;
}

.content-strip h2 {
  font-size: 28px;
}

@media only screen and (max-width: 1024px) {
  .content-strip h2 {
    font-size: 22px;
  }
}

.content-strip .strip-title {
  margin-bottom: 15px;
}

.content-panel {
  padding: 30px;
  background: #f2f2f2;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}

.content-panel h2 {
  line-height: 44px;
}

.content-panel.post-card {
  padding: 0;
}

.content-panel.post-card .inner {
  padding: 30px;
}

@media (max-width: 767px) {
  .content-panel.post-card img {
    width: 100%;
  }
}

.content-panel .follow {
  color: #f7d3d7;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  right: 30px;
  top: 30px;
}

.content-panel .follow .btn-default, .content-panel .follow .page-numbers {
  margin-left: 10px;
}

@media only screen and (max-width: 1024px) {
  .content-panel .follow {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 30px;
  }
}

.red-bg {
  background: #ac1f2d;
  color: #fff;
}

.red-bg h2 {
  color: #fff;
}

.red-bg a.btn-default, .red-bg a.page-numbers, .red-bg a.btn-default:active, .red-bg a.page-numbers:active, .red-bg a.btn-default:visited, .red-bg a.page-numbers:visited {
  background: #fff;
  color: #ac1f2d;
}

.red-bg a.btn-default:before, .red-bg a.page-numbers:before, .red-bg a.btn-default:active:before, .red-bg a.page-numbers:active:before, .red-bg a.btn-default:visited:before, .red-bg a.page-numbers:visited:before {
  background: #dfdfdf;
}

.page-content span.wpcf7-not-valid-tip {
  color: #fff;
  background-color: #ac1f2d;
  font-size: 1em;
  font-weight: normal;
  display: block;
  padding: 10px 20px;
  display: block;
  clear: both;
  margin-top: 0px;
  width: 80%;
}

.page-content span.wpcf7-valid-tip {
  color: #fff;
  background-color: #44978c;
  font-size: 1em;
  font-weight: normal;
  display: block;
  padding: 10px 20px;
  display: block;
  clear: both;
  margin-top: 0px;
  width: 80%;
}

.page-content div.wpcf7-validation-errors {
  color: #fff;
  background-color: #919191;
  border: 0;
  font-size: 1em;
  font-weight: normal;
  display: block;
  padding: 10px 20px;
  display: block;
  clear: both;
  margin-top: 0px;
  width: 80%;
  margin-left: 0;
}

.page-content div.wpcf7-mail-sent-ok {
  color: #fff;
  background-color: #44978c;
  border: 0;
  font-size: 1em;
  font-weight: normal;
  display: block;
  padding: 10px 20px;
  display: block;
  clear: both;
  margin-top: 0px;
  width: 80%;
  margin-left: 0;
}

.page-content input, .page-content button, .page-content select, .page-content textarea {
  margin: 0;
  line-height: inherit;
  width: 80%;
  padding: 10px;
  border: 1px solid #dfdfdf;
}

.page-content input[type=submit] {
  border: 1px solid #44978c;
}

.page-content input[type=submit]:before {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #288376;
  content: "";
  transition: height 0.3s;
  z-index: 0;
}

.page-content input:focus, .page-content select:focus, .page-content textarea:focus {
  border: 1px solid #44978c;
  outline: none;
}

.page-content select {
  height: 46px;
  padding: 0.5em 3.5em 0.5em 1em;
}

.page-content .contactSubmit {
  width: 80%;
  height: auto;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.page-content .contactSubmitHidden {
  display: none;
}

.homepage-mobile-whitespace {
  width: 100%;
  height: 25px;
  display: block;
  background-color: #fff;
}

@media (min-width: 768px) {
  .homepage-mobile-whitespace {
    display: none;
  }
}

.homepage-mobile-greyspace {
  width: 100%;
  height: 25px;
  display: block;
  background-color: #f2f2f2;
}

@media (min-width: 768px) {
  .homepage-mobile-greyspace {
    display: none;
  }
}

header {
  background: #2f2f2f;
  width: 100%;
  color: #fff;
  padding: 16px 0;
}

@media only screen and (max-width: 1024px) {
  header .col-md-10 {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  header .col-md-2 {
    position: absolute;
    width: 20%;
    padding-left: 2em;
  }
}

@media only screen and (max-width: 1198px) {
  header .container {
    max-width: 70rem;
  }
}

@media only screen and (max-width: 1024px) {
  header .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .logo {
    position: relative;
    top: 7px;
    width: 90px;
    max-width: 90px;
  }
}

#menu-main-menu {
  padding: 0;
  text-align: right;
  float: right;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  #menu-main-menu {
    width: 100%;
    display: none;
    padding-top: 10px;
    text-align: left;
    font-size: 0.8em;
  }
}

#menu-main-menu ul {
  margin: 0;
}

#menu-main-menu > li {
  list-style: none;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  #menu-main-menu > li {
    display: block;
    width: 100%;
  }
}

#menu-main-menu > li a {
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  #menu-main-menu > li a {
    width: 100%;
    padding-left: 2.7em;
  }
}

#menu-main-menu > li .sub-menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  #menu-main-menu > li .sub-menu {
    display: block;
    background: #222;
    z-index: 10;
    padding: 0;
  }
  #menu-main-menu > li .sub-menu li {
    display: block;
  }
  #menu-main-menu > li .sub-menu li a, #menu-main-menu > li .sub-menu li a:active, #menu-main-menu > li .sub-menu li a:visited {
    display: block;
    padding: 10px 5em;
    font-size: 0.8em;
    color: #fff;
    text-transform: uppercase;
  }
  #menu-main-menu > li .sub-menu li:hover {
    background: #288376;
  }
  #menu-main-menu > li .sub-menu li:hover a {
    text-decoration: none;
  }
}

#menu-main-menu > li.current_page_item a, #menu-main-menu > li.current_page_ancestor a {
  background: #ac1f2d;
}

#menu-main-menu > li.current_page_item ul li a, #menu-main-menu > li.current_page_ancestor ul li a {
  background: none;
}

#menu-main-menu > li.current_page_item ul li.current_page_item, #menu-main-menu > li.current_page_item ul li.current_page_ancestor, #menu-main-menu > li.current_page_ancestor ul li.current_page_item, #menu-main-menu > li.current_page_ancestor ul li.current_page_ancestor {
  background: #ac1f2d;
}

@media only screen and (max-width: 1024px) {
  #menu-main-menu > li.current_page_ancestor a {
    background: none;
  }
}

@media only screen and (min-width: 769px) {
  #menu-main-menu > li:hover {
    overflow: visible;
  }
  #menu-main-menu > li:hover > a {
    background: #44978c;
    text-decoration: none;
  }
  #menu-main-menu > li:hover > ul.sub-menu {
    display: block;
    background: #44978c;
    position: absolute;
    top: 100%;
    height: auto;
    width: 200px;
    text-align: left;
    padding: 10px 0;
    z-index: 10;
  }
  #menu-main-menu > li:hover > ul.sub-menu li {
    display: block;
  }
  #menu-main-menu > li:hover > ul.sub-menu li a {
    display: block;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
  }
  #menu-main-menu > li:hover > ul.sub-menu li:hover {
    background: #288376;
  }
  #menu-main-menu > li:hover > ul.sub-menu li:hover a {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  #menu-main-menu > li .sub-menu {
    display: none;
  }
}

#menu-main-menu > li .mobile-menu-plus {
  display: inline;
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 2em;
  width: 40px;
  text-align: center;
}

@media (min-width: 768px) {
  #menu-main-menu > li .mobile-menu-plus {
    display: none;
  }
}

header .social-links {
  float: right;
}

@media only screen and (max-width: 1024px) {
  header .social-links {
    padding-right: 2em;
  }
}

header .social-links ul {
  padding: 0;
  margin: 0;
}

header .social-links ul li {
  display: inline-block;
  list-style: none;
}

header .social-links ul li a {
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  text-transform: uppercase;
}

header .social-links ul li a:hover {
  background: #44978c;
  text-decoration: none;
}

header .social-links ul li.mobile-menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  header .social-links ul li.mobile-menu {
    display: inline-block;
  }
}

footer {
  background: #2f2f2f;
  padding: 16px 0;
  color: #919191;
}

footer a {
  color: #44978c;
  transition: color, 0.3s;
}

footer a:hover {
  color: #7dc4bb;
  text-decoration: none;
}

footer .contact-info {
  text-align: right;
}

footer #menu-footer-menu, footer .social-links ul {
  margin: 0;
  padding: 16px 0 0 0;
  text-align: right;
  float: right;
}

footer #menu-footer-menu li, footer .social-links ul li {
  list-style: none;
  display: inline-block;
  margin-left: 15px;
}

footer #menu-footer-menu li a, footer .social-links ul li a {
  color: #919191;
}

footer #menu-footer-menu li a:hover, footer .social-links ul li a:hover {
  color: #f2f2f2;
}

@media only screen and (max-width: 1024px) {
  footer .col-md-2 {
    position: absolute;
  }
  footer img {
    max-width: 90px;
  }
}

.home-banner-container {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 767px) {
  .home-banner-container {
    top: 100%;
    transform: translate(0%, 0%);
    width: 100%;
    background-color: #ac1f2d;
  }
}

.home-slideshow {
  height: 500px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .home-slideshow {
    height: 380px;
  }
}

@media (max-width: 767px) {
  .home-slideshow {
    height: 300px;
  }
}

.home-banner {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.home-banner:first-child {
  display: block;
}

.home-banner-panel {
  padding: 30px 30px 30px 0;
  color: #fff;
  background: #ac1f2d;
  font-size: 22px;
  position: relative;
}

.home-banner-panel:before {
  content: "";
  display: block;
  height: 100%;
  width: 500%;
  right: calc(100%);
  top: 0;
  background: #ac1f2d;
  position: absolute;
  overflow: visible;
  z-index: -1;
}

.home-banner-panel h1 {
  margin-top: 0;
}

@media only screen and (max-width: 1024px) {
  .home-banner-panel h1 {
    font-size: 22px;
    line-height: 27px;
  }
}

.home-banner-panel p {
  margin-bottom: 0;
}

@media only screen and (max-width: 1024px) {
  .home-banner-panel p {
    font-size: 15px;
  }
}

.youtube-embed {
  width: 100% !important;
}

.twitter-timeline {
  background: #fff;
}

.page h1 {
  font-weight: 700;
  color: #ac1f2d;
}

.post-page .page-content {
  padding-top: 40px;
}

.share-links {
  text-align: right;
}

.share-links ul {
  margin: 0;
  padding: 0;
}

.share-links li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;
}

.folder {
  background: #dfdfdf;
  margin-bottom: 60px;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.folder .open-icon, .folder .close-icon {
  background-color: #ac1f2d;
  color: #fff;
  font-weight: 700;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 40px;
  text-align: center;
  padding-top: 0;
  font-size: 29px;
  line-height: 0;
  padding-top: 11px;
  position: absolute;
  position: absolute;
  right: 28px;
  top: 16px;
}

.folder .close-icon {
  font-size: 40px;
  padding-top: 10px;
}

.folder .open-icon {
  display: none;
}

.folder:last-of-type {
  margin: 0;
}

.folder .folder-heading {
  font-size: 22px;
  line-height: 60px;
  text-decoration: none;
  color: #ac1f2d;
}

.folder .folder-heading:before {
  display: block;
  float: left;
  margin: 0 16px;
  width: 36px;
  height: 60px;
  background: url("../images/folder-closed.png") center center/contain no-repeat;
  content: "";
}

.folder.closed .folder-heading {
  color: #2f2f2f;
}

.folder.closed .open-icon {
  display: block;
}

.folder.closed .close-icon {
  display: none;
}

@media (max-width: 767px) {
  .folder .hide-on-mobile {
    display: none;
  }
}

.folder .resources-list .container-fluid {
  padding: 0;
}

@media (max-width: 767px) {
  .folder .resources-list .container-fluid-resources-mobile {
    text-align: center;
  }
}

.folder .resources-list .btn-default.download, .folder .resources-list .download.page-numbers {
  position: relative;
  top: -10px;
  float: right;
}

@media (max-width: 767px) {
  .folder .resources-list .btn-default.download, .folder .resources-list .download.page-numbers {
    float: none;
    margin: 0 auto;
    display: block;
    top: 0px;
  }
}

.folder .resources-sep-mobile {
  width: 100px;
  margin: 15px auto;
}

@media (min-width: 768px) {
  .folder .resources-sep-mobile {
    display: none;
  }
}

.folder .folder-content {
  cursor: initial;
  display: none;
}

.folder .folder-content .folder-table {
  padding: 20px 30px;
  background: #f2f2f2;
  font-size: 18px;
}

.folder .folder-content .folder-table .resources-list {
  width: 100%;
}

.folder .folder-content .folder-table .resources-list .infocell {
  padding: 10px 0;
}

.folder .folder-content .folder-table .resources-list .infocell.heading {
  color: #919191;
}

.folder .download-all {
  background: #dfdfdf;
  text-align: center;
  padding: 24px 0;
}

.news-listing h1.strip-title {
  color: #ac1f2d;
}

.news-listing .post-card {
  margin-bottom: 30px;
}

.news-listing .post-card .inner {
  padding-left: 0;
}

@media only screen and (max-width: 768px) {
  .news-listing .post-card .inner {
    padding-left: 30px;
  }
}

.post-info {
  color: #919191;
}

.post-info a {
  color: #2f2f2f;
}

.post-info a:hover {
  color: #ac1f2d;
}

.thumb-container {
  width: 100%;
  padding-bottom: 92%;
  overflow: hidden;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-position 0.3s;
}

.searchforms {
  text-align: right;
}

.searchforms label {
  margin-right: 10px;
  margin-left: 20px;
}

.blog-search {
  text-align: right;
  display: inline-block;
  padding-bottom: 16px;
}

.blog-search .search-container {
  background: #fff;
  display: inline-block;
  padding: 5px 10px;
  line-height: 24px;
  font-size: 16px;
  border-bottom: 3px #288376 solid;
}

.blog-search .search-container .search-input {
  border: 0;
  padding: 0;
}

.blog-search button {
  font-family: "FontAwesome";
  font-style: normal;
  border: 0;
  background: none;
  color: #44978c;
}

.blog-search button i {
  font-style: normal;
}

.pagination {
  width: 100%;
  text-align: center;
}

select {
  border-width: 0 0 3px 0;
  border-color: #288376;
  padding: 8px 10px;
  background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 18px;
  padding: 0.5em 3.5em 0.5em 1em;
  height: 39px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, #288376 50%), linear-gradient(135deg, #288376 50%, transparent 50%), linear-gradient(to right, white, white);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%), linear-gradient(135deg, transparent 50%, white 50%), linear-gradient(to right, #288376, #288376);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  outline: 0;
}

.page-numbers.current {
  background-color: #288376;
}

a.page-numbers:after, a.page-numbers:active:after, .page-numbers:after {
  display: none;
}

a.prev, a.prev:active, a.prev:visited, .prev {
  position: absolute;
  left: 14px;
}

@media only screen and (max-width: 1024px) {
  a.prev, a.prev:active, a.prev:visited, .prev {
    width: 100%;
    position: relative;
    left: 0;
    margin-bottom: 6px;
  }
}

a.next, a.next:active, a.next:visited, .next {
  position: absolute;
  right: 14px;
}

@media only screen and (max-width: 1024px) {
  a.next, a.next:active, a.next:visited, .next {
    width: 100%;
    position: relative;
    right: 0;
    margin-top: 6px;
  }
}

a.page-numbers.prev:after, .page-numbers.prev:after {
  display: inline-block;
  float: left;
  left: 0;
  margin-right: 11px;
  transform: rotate(180deg);
  margin-left: 0;
}

a.page-numbers.next:after, .page-numbers.next:after {
  display: inline-block;
}

.current:before {
  display: none;
}

.page-numbers:after {
  display: none;
}

.page-numbers:after {
  display: none;
}

.inactive {
  border: 0;
  background-color: #919191;
  pointer-events: none;
}

.inactive:before {
  height: 0;
}

.inactive:focus {
  outline: 0;
}

.inactive:hover:before {
  height: 0;
}
