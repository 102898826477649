.folder {
	background:$grey_med;
	margin-bottom:60px;
	box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.05);
	overflow: hidden;
	cursor: pointer;
	position: relative;

	.open-icon, .close-icon {
		background-color: $red;
		color: $white;
		font-weight: 700;
		display: block;
	    width: 25px;
	    height: 25px;
	    border-radius: 40px;
	    text-align: center;
	    padding-top: 0;
	    font-size: 29px;
	    line-height: 0;
	    padding-top: 11px;
	    position: absolute;
	    position: absolute;
	    right: 28px;
	    top: 16px;
	}

	.close-icon {
		font-size: 40px;
		padding-top: 10px;
	}

	.open-icon {
		display: none;
	}

	&:last-of-type {
		margin:0;
	}
	.folder-heading {
		font-size:22px;
		line-height: 60px;
		
		text-decoration: none;
		color: $red;
		&:before {
			display: block;
			float:left;
			margin:0 16px;
			width:36px;
			height:60px;
			background:url('../images/folder-closed.png') center center / contain no-repeat;
			content:"";
		}
			
	}
	&.closed {
		.folder-heading {
			color: $charcoal;
		}
		.open-icon {
			display: block;
		}
		.close-icon {
			display: none;
		}
	}
	.hide-on-mobile {
		@media (max-width: 767px) {
			display: none;
		}
	}
	.resources-list {
		.container-fluid {
			padding:0;
		}
		.container-fluid-resources-mobile {
			@media (max-width: 767px) {
				text-align: center;
			}
		}
		.btn-default.download {
			position: relative;
			top:-10px;
			float:right;

			@media (max-width: 767px) {
				float: none;
				margin: 0 auto;
				display: block;
				top: 0px;
			}
		}
	}
	.resources-sep-mobile {
		width: 100px;
		margin: 15px auto;
		@media (min-width: 768px) {
			display: none;
		}
	}
	.folder-content {
		cursor: initial;
		display: none;
		.folder-table {
			padding:20px 30px;
			background:$grey;
			font-size: 18px;
			.resources-list {
				width:100%;
				.infocell {
					padding:10px 0;
					&.heading {					
						color:$grey_dark;
					}
				}
			}
		}
	}
	.download-all {
		background: $grey_med;
		text-align: center;
		padding:24px 0;
	}
}