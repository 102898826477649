header {
	background:$charcoal;
	width:100%;
	color:$white;
	padding:16px 0;

	.col-md-10 {
		@media only screen and (max-width: 1024px) { 
			width: 100%;
			padding:0;
		}
	}

	.col-md-2 {
		@media only screen and (max-width: 1024px) { 
			position: absolute;
			width: 20%;
			padding-left: 2em;
		}
	}

	.container {

		@media only screen and (max-width: 1198px) { 
			max-width: 70rem;
		}
		
		@media only screen and (max-width: 1024px) { 
			max-width: 100%;
		}
	}
}

.logo {

	@media only screen and (max-width: 1024px) { 
		position: relative;
		top: 7px;
		width: 90px;
		max-width: 90px;
	}

}

#menu-main-menu {
	padding:0;
	text-align: right;
	float:right;
	font-weight: bold;
	margin:0;
	@media only screen and (max-width: 1024px) { 
		width: 100%;
		display: none;
	    padding-top: 10px;
	    text-align: left;
    	font-size: 0.8em;
	}
	ul {
		margin:0;
	}
	> li {
		list-style:none;
		display: inline-block;
		position: relative;
		@media only screen and (max-width: 1024px) { 
			display: block;
			width: 100%;
		}
		a {
			display: inline-block;
			padding:10px 15px;
			color:$white;
			text-transform: uppercase;
			text-decoration:none;
			@media only screen and (max-width: 1024px) { 
				width: 100%;
				padding-left: 2.7em;
			}
		}
		.sub-menu {
			display:none;
			@media only screen and (max-width: 1024px) { 
				display: block;
				background: #222;
				z-index:10;
				padding:0;
				li {
					display: block;
					a, a:active, a:visited {
						display: block;				
						padding:10px 5em;
						font-size: 0.8em;
						color:$white;
						text-transform: uppercase;
					}
					&:hover {
						background:$turquoise_dark;
						a {
							text-decoration: none;
						}
					}
				}
			}
		}
		&.current_page_item, &.current_page_ancestor {
			a {
				background:$red;
			}
			ul li {
				a {
					background: none;
				}
				&.current_page_item, &.current_page_ancestor {
					background: $red;
				}

			}
		}
		&.current_page_ancestor {
			@media only screen and (max-width: 1024px) { 
				a {
					background: none;
				}
			}	
		}
		&:hover {
			@media only screen and (min-width: 769px) { 
				overflow:visible;
				> a {
					background:$turquoise;
					text-decoration:none;
				}
				> ul.sub-menu {
					display: block;
					background: $turquoise;
					position: absolute;
					top:100%;
					height:auto;
					width:200px;
					text-align: left;
					padding:10px 0;
					z-index:10;
					li {
						display: block;
						a {
							display: block;				
							padding:10px 15px;
							color:$white;
							text-transform: uppercase;
						}
						&:hover {
							background:$turquoise_dark;
							a {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
		.sub-menu {
			@media (max-width: 767px) {
				display: none;
			}
		}
		.mobile-menu-plus {
			display: inline;
			position: absolute;
			top: 10px;
			right: 0px;
			font-size: 2em;
			width: 40px;
			text-align: center;

			@media (min-width: 768px) {
				display: none;
			}
		}
	}

	
}

header .social-links {
	float:right;
	@media only screen and (max-width: 1024px) { 
		padding-right: 2em;
	}
	ul {
		padding:0;
		margin:0;
		li {
			display:inline-block;
			list-style: none;
			a {
				display: inline-block;
				padding:10px 15px;
				color:$white;
				text-transform: uppercase;
				&:hover {
					background:$turquoise;
					text-decoration:none;
				}
			}
		}
		li.mobile-menu {
			display: none;
			@media only screen and (max-width: 1024px) { 
				display: inline-block;
			}
		}
	}
}