footer {
	background:$charcoal;
	padding:16px 0;
	color:$grey_dark;
	a {
		color:$turquoise;
		transition:color, 0.3s;
		&:hover {
			color:lighten($turquoise, 20%);
			text-decoration: none;
		}
	}
	.contact-info {
		text-align: right;
	}
	#menu-footer-menu, .social-links ul {
		margin:0;
		padding:16px 0 0 0;
		text-align:right;
		float:right;
		li {
			list-style:none;
			display: inline-block;
			margin-left:15px;
			a {
				color:$grey_dark;
				&:hover {
					color:$grey;
				}
			}
		}
	}
	@media only screen and (max-width: 1024px) { 
		.col-md-2 {
			position: absolute;
		}
		img {
			max-width: 90px;
		}
	}
}