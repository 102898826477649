.home-banner-container {
	width:100%;	
	position:absolute;
	top:50%;
	transform: translate(0,-50%);

	@media (max-width: 767px) {
		top: 100%;
		transform: translate(0%, 0%);
		width: 100%;
		background-color: $red;
	}
}

.home-slideshow {
	height: 500px;
	position: relative;
	@media only screen and (max-width: 1024px) { 
		height: 380px;
	}

	@media (max-width: 767px) {
		height: 300px;
	}
}

.home-banner {
	display: none;
	position: absolute;
	top:0;
	left:0;
	width: 100%;
}

.home-banner:first-child {
	display: block;
}

.home-banner-panel {
	padding:30px 30px 30px 0;
	color:$white;
	background:$red;
	font-size:22px;
	position:relative;
	&:before {
		content:"";
		display: block;
		height:100%;
		width:500%;
		right:calc(100%);
		top:0;
		background: $red;
		position:absolute;
		overflow:visible;
		z-index:-1;
	}
	h1 {
		margin-top:0;
		@media only screen and (max-width: 1024px) { 
			font-size: 22px;
    		line-height: 27px;
		}
	}
	p {
		margin-bottom:0;
		@media only screen and (max-width: 1024px) { 
			font-size: 15px;
		}
	}
}

.youtube-embed {
	width:100% !important;
}

.twitter-timeline {
	background:$white;
}